<template>
  <div>
    <nav-header :title="navTitle"></nav-header>
    <div v-if="collection != null && !collection.deleted" class="container">
      <loading :active="isLoading" :is-full-page="true"></loading>
      <div class="card mb-4">
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col-12">
              {{ collection.date }} {{ collection.startTime }} -
              {{ collection.endTime }}
            </div>
            <div class="col-12">
              {{ $englishPreferred ? "Location" : "Lokalitet" }}:
              <span v-if="collection.site != null">{{
                collection.site.na
              }}</span>
            </div>
          </div>
          <div>
            <router-link
              v-if="collection != null"
              :to="{
                name: 'EditCollectionData',
                params: { id: collection.id },
              }"
              class="btn form-control btn-primary"
            >
              {{ $englishPreferred ? "Edit tour data" : "Ret turdata" }}
              <i class="material-icons button-material-icon md-18"> edit </i>
            </router-link>
          </div>
        </div>
      </div>
      <observation-form-area
        class="mt-2"
        :currentObservation="currentObservation"
        :collectionForm="collection"
      >
      </observation-form-area>
    </div>
  </div>
</template>

<script>
import collectionRepository from "../idb/repositories/collectionRepository";
import NavHeader from "@/components/navbars/NavHeader.vue";
import observationRepository from "../idb/repositories/observationRepository";
import ObservationFormArea from "../components/observation/ObservationFormArea.vue";
import axios from "axios";
// Import component
import Loading from "vue3-loading-overlay";
// Import stylesheet
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

let localObservations = [];

export default {
  components: {
    NavHeader,
    ObservationFormArea,
    Loading,
  },
  data() {
    return {
      collection: null,
      isLoading: true,
      navTitle: this.$englishPreferred ? "Edit count" : "Rediger tælling",
      currentTab: "Mandatory",
      tabs: [
        { enTitle: "Mandatory", daTitle: "Påkrævet" },
        { enTitle: "Optional", daTitle: "Valgfrit" },
      ],
      currentObservation: {
        apiId: null,
        collectionId: this.$route.params.id,
        collectionApiId: null,
        count: 1,
        specie: null,
        age: 1,
        sex: "-",
        plumage: 1,
        secret: false,
        notes: null,
        calling: false,
        heard: false,
        migratingInwards: false,
        migratingOutwards: false,
        oilDamaged: false,
        startTime: null,
        endTime: null,
        coordinate: {
          latitude: null,
          longitude: null,
          radius: 50,
        },
        chosenBehaviours: {
          primary: null,
          secondary: null,
          direction: null,
        },
      },
    };
  },
  methods: {
    async getCollection() {
      collectionRepository
        .getCollection(this.$route.params.id)
        .then((value) => this.prepareCollection(value));
    },
    getAPIObservations() {
      return axios.get(
        "/spontan/collections/" + this.collection.apiId + "/observations"
      );
    },
    async getLocalObservations() {
      this.localObservations =
        await observationRepository.getObservationsByCollectionId(
          this.$route.params.id
        );
      return true;
    },
    async prepareCollection(collection) {
      this.collection = collection;
      let isPrepared = false;
      // Check if user is online
      let isOnline = window.navigator.onLine ? true : false;
      // Before displaying observations, make sure that synchronization with API is taken care of
      if (await this.getLocalObservations()) {
        if (isOnline) {
          // User is online
          // 1. Get observations from API
          await this.getAPIObservations()
            .then((response) => {
              console.log("retrieved observations from API");
              // 1.a If retrieved loop through observations
              // 1.a.1 update existent non-unsynchronized/non-deleted observations and insert those not existent locally
              response.data.forEach((observation) => {
                let isNewObservation = false;

                // Check if this observation already exists locally (if a local has same API ID)
                // If there's a local, set the local ID on the received one to be the same as the local one,
                // and then update/insert using 'put' in indexedDB

                const i = this.localObservations.findIndex(
                  (e) => e.apiId == observation.apiId
                );
                if (i > -1) {
                  // Observation was found, checking if local observation is changed or deleted
                  console.log("Observation found");
                  if (
                    this.localObservations[i]?.unsynched ||
                    this.localObservations[i]?.deleted
                  ) {
                    // If local observation is changed or deleted, simply return and do nothing
                    return;
                  } else {
                    // If not unsynched or deleted locally, we set the local id on the received observation
                    observation.id = this.localObservations[i].id;
                  }
                } else {
                  isNewObservation = true;
                }
                // Insert/put the received observation as local
                observation.collectionId = this.collection.id;
                observation.collectionApiId = this.collection.apiId;
                observationRepository.insertObservation(observation);
              });
            })
            .catch((error) => {
              console.log("Could not receive API Observations");
            });
        }
        await observationRepository
          .getObservationsByCollectionId(this.$route.params.id)
          .then((observations) => {
            console.log("retrieved updated local observations ");
            this.collection.observations = observations;
          });

        // Synchronize changes in observations in this collection to API
        let finishedSyncToApi = await this.synchronizeObservationsToApi();

        if (finishedSyncToApi) {
          this.isLoading = false;
        }
      }
    },
    async synchronizeObservationsToApi() {
      console.log("synkroniserer listen med API");
      let shouldReload = false;
      for (let observation of this.collection.observations) {
        let unwrappedObservation = {
          ...JSON.parse(JSON.stringify(observation)),
        };
        if (observation.apiId == null && observation.deleted != true) {
          console.log("inserting to API");
          //Insert observation in API
          axios
            .post("/spontan/observations/", unwrappedObservation)
            .then((response) => {
              let observationApiId = response.data;

              unwrappedObservation.apiId = observationApiId;
              observation.apiId = observationApiId;
              observation.unsynched = false;

              observationRepository.insertObservation(unwrappedObservation);
            })
            .catch((error) => {
              if (
                error.response &&
                error.response.status === 403 &&
                JSON.stringify(error.response.data).indexOf(
                  "Duplicate entry"
                ) !== -1
              ) {
                // Handle 400 error
                console.log(
                  "Observation could not be inserted due to bad request, removing it locally"
                );
                observationRepository.deleteObservationById(observation.id);
                shouldReload = true;
              } else {
                // Handle other errors
                console.log("Server did not respond, don't delete locally yet");
              }
            });
        } else if (observation.apiId != null && observation.unsynched == true) {
          console.log("updating in API");
          // Update observation in API
          observationRepository
            .insertObservation(unwrappedObservation)
            .then(() => {
              // Update observation in API, and when done, update locally as well
              axios
                .put(
                  "/spontan/observations/" + observation.apiId,
                  unwrappedObservation
                )
                .then((response) => {
                  console.log(
                    "Successfully updated in DOFbasen, now updating in local database"
                  );
                  // Success, now update locally with unsynched marked as false
                  unwrappedObservation.unsynched = false;
                  observation.unsynched = false;
                  observationRepository.insertObservation(unwrappedObservation);
                })
                .catch((error) => {
                  console.log("Server did not respond");
                });
            });
        } else if (observation.apiId != null && observation.deleted == true) {
          // Delete a local observation marked as deleted in API
          // Delete observation in API, and when done, delete locally as well
          axios
            .delete("/spontan/observations/" + observation.apiId)
            .then((response) => {
              observationRepository.deleteObservationById(observation.id);
            })
            .catch((error) => {
              console.log("Server did not respond, don't delete locally yet");
            });
        }
      }
      if (shouldReload === true) {
        location.reload();
      }
      // Finished interacting with API
      return true;
    },
  },
  beforeMount() {
    this.getCollection();
  },
};
</script>

<style scoped>
/* .card {
        background-color: #D5D6D7;
    } */
</style>
